import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'

// firebase.initializeApp({
//     apiKey: "AIzaSyByl4EQFXgHIyk-7tjYADMEZKZZiy4c5tY",
//     authDomain: "nubepos-present.firebaseapp.com",
//     projectId: "nubepos-present",
//     storageBucket: "nubepos-present.appspot.com",
//     messagingSenderId: "452444589638",
//     appId: "1:452444589638:web:3a56f8f29b21508bae0447",
//     measurementId: "G-EXKCZT1DNL"
// })

firebase.initializeApp({
    apiKey: 'AIzaSyCYlkXAuRnFKxEz4XmeYjd6PXE5_DaAiQA',
    authDomain: 'fastbuild-taig.firebaseapp.com',
    projectId: 'fastbuild-taig',
    storageBucket: 'fastbuild-taig.appspot.com',
    messagingSenderId: '66923393690',
    appId: '1:66923393690:web:5d5585103dd0b133426523',
    measurementId: 'G-CSVM7F55ZW',
})

export const db = firebase.firestore()
export const auth = firebase.auth()
export const functions = firebase.functions()
export const storage = firebase.storage()
