import { firestoreAction } from 'vuexfire'
import { db } from '@/firebase'
import { renderProductSubTotal, renderGrandTotal } from '@/utils/index'
import _ from 'lodash'
import moment from 'moment'

export default {
    state: {
        orders: null,
    },
    mutations: {},
    actions: {
        bindOrders({ dispatch }) {
            return dispatch('bindCollection', 'orders')
        },

        bindOrdersByCustomer: firestoreAction(({ bindFirestoreRef }, id) => {
			return bindFirestoreRef('orders', db.collection('orders').where('customer.id', '==', id).orderBy('uniNum', 'desc'))
		}),

        async createOrder({ state }) {
            let newOrder = {
                uniNum: '',
                type: state.cart.type,
                method: state.cart.method,
                shop: {
                    id: state.shop.id,
                    name: state.shop.name,
                    address: state.shop.address,
                    phone: state.shop.phone,
                },
                customer: {
                    id: state.customer.id,
                    name: state.customer.name,
                    phone: state.customer.phone,
                    address: state.cart.address,
                },
                products: state.cart.products,
                remark: state.cart.remark,
                grandTotal: 0,
                payment: null,
                status: 1,
                createTime: moment().format('X'),
                reservationTime: state.cart.reservationTime,
                doingTime: null,
                awaitTime: null,
                completeTime: null,
            }
            _.forEach(newOrder.products, product => {
                product.subTotal = renderProductSubTotal(state.cart.method, product)
            })
            newOrder.grandTotal = renderGrandTotal(state.cart)
            
            let orderRef = db.collection('orders').doc()
            let shopRef = db.collection('shops').doc(newOrder.shop.id)
            newOrder.id = orderRef.id
            return await db.runTransaction(transaction => {
                return transaction.get(shopRef).then(shopDoc => {
                    let shop = shopDoc.data()
                    const today = moment().format('YYYYMMDD')
                    const reservationTime = moment.unix(newOrder.reservationTime).format('YYYYMMDD')
                    const currentUniNum = shop.uniNums[0]
                    const currentUniNumDate = String(currentUniNum).substring(0, 8)
                    if (today == currentUniNumDate) {
                        if (reservationTime > today) {
                            let index = reservationTime - today
                            newOrder.uniNum = shop.uniNums[index] + 1
                            shop.uniNums[index] = shop.uniNums[index] + 1
                        } else {
                            newOrder.uniNum = currentUniNum + 1
                            shop.uniNums[0] = currentUniNum + 1
                        }
                    } else {
                        let index = today - currentUniNumDate
                        shop.uniNums.splice(0 ,index)
                        if (shop.uniNums[0]) {
                            newOrder.uniNum = shop.uniNums[0] + 1
                            shop.uniNums[0] = shop.uniNums[0] + 1
                        } else {
                            shop.uniNums[0] = Number(today + '001')
                            newOrder.uniNum = shop.uniNums[0]
                        }
                    }
                    console.log(shop)
                    transaction.update(shopRef, shop)
                    transaction.set(orderRef, newOrder)
                })
            }).then(() => {
                return newOrder
            })
        },

        async getOrder({ dispatch }, id) {
            return await dispatch('getDocument', { collection: 'orders', id: id })
        },

        async updateOrder({ dispatch }, data) {
            return await dispatch('updateDocument', { collection: 'orders', data: data })
        },
    },
}
