import ListHeader from '@/components/ListHeader'
import ListTable from '@/components/ListTable'
import { mapState } from 'vuex'

export default {
    components: {
        ListHeader,
        ListTable,
    },

    data() {
        return {
            fields: [
                {
                    key: 'uniNum',
                    label: '訂單',
                    sortable: true, 
                    sortDirection: 'desc',
                },
                {
                    key: 'method',
                    label:'類別',
                    formatter: value => {
                        switch(value) {
                            case 'toGo':
                                return '現場自取'
                            case 'mobileToGo':
                                return '手機自取'
                            case 'mobileDelivery':
                                return '手機外送'
                        }
                    }
                },
                {
                    key: 'customer',
                    label:'客戶',
                    formatter: value => {
                        return value ? value.name : '現場客戶'
                    }
                },
                {
                    key: 'grandTotal',
                    label: '總計',
                    formatter: value => {
                        return '$' + value
                    }
                },
                {
                    key: 'actions',
                    label: '功能',
                    thStyle: { width: '25%' },
                }
            ]
        }
    },

    computed: {
        ...mapState({
            orders: state => state.orders,
        })
    }
}