// NubeTech Vuex v1.5 (21032305)
import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { db, storage } from '@/firebase'  //updated by wyman 19-4-2021
import _ from 'lodash'
import cart from './cart'
import customer from './customer'
import order from './order'
import shop from './shop'
import category from './category'
import product from './product'
import extra from './extra'
import item from './item'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
		...cart.state,
		...customer.state,
		...order.state,
		...shop.state,
		...category.state,
		...product.state,
		...extra.state,
        ...item.state,

		shop: null,
        customer: null,
    },
    mutations: {
        ...vuexfireMutations,
		...cart.mutations,
		...customer.mutations,
		...order.mutations,
		...shop.mutations,
		...category.mutations,
		...product.mutations,
		...extra.mutations,
		...item.mutations,
    },
    actions: {
		...cart.actions,
		...customer.actions,
		...order.actions,
		...shop.actions,
		...category.actions,
		...product.actions,
		...extra.actions,
        ...item.actions,

		bindCollection: firestoreAction(({ bindFirestoreRef }, collection) => {
			return bindFirestoreRef(collection, db.collection(collection))
		}),

		bindDocument: firestoreAction(({ bindFirestoreRef }, { collection, target, id }) => {
			return bindFirestoreRef(target, db.collection(collection).doc(id))
		}),

		unbindState: firestoreAction(({ unbindFirestoreRef }, target) => {
			unbindFirestoreRef(target)
		}),

		async createDocument(store, { collection, data }) {
			data = JSON.parse(JSON.stringify(data))
			let ref = null
			if (data.id) {
				ref = db.collection(collection).doc(data.id)
			} else {
				ref = db.collection(collection).doc()
				data.id = ref.id
			}
			return await ref.set(data).then(() => {
				return data
			}).catch(error => {
				console.log(error)
				return false
			})
		},

		getDocument({ state }, { collection, id }) {
			return _.find(state[collection], { id: id })
		},

		async updateDocument(store, { collection, data }) {
			data = JSON.parse(JSON.stringify(data))
			return await db.collection(collection).doc(data.id).update(data).then(() => {
				return data
			}).catch(error => {
				console.log(error)
				return false
			})
		},

		async createFile(store, { path, file }) {
			await storage.ref().child(path).put(file)
			return await storage.ref().child(path).getDownloadURL()
		}
    },
})
